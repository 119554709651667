

  
  .RoleView__container {
    padding-bottom: 80px;
  }
  .save-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: pink;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    padding-left: 50px;
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust this value as needed */
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 10px; /* optional: to add some space between the link and the button */
}
  .cancel-link {
    color: white;
    text-decoration: none;
    margin-right: 10px; /* optional: to add some space between the link and the text */
}

  .RoleView__Columns {
    display: flex;
    background: #36C6F4;
    justify-content: center;

  }
  .RoleView__Columns__left {
    display: flex;
    margin-top: 80px;
    margin-left: 20px;
    padding:50px;
    width: 50%;
    min-width: 500px;
    max-width: 700px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    
  }

  .cast__list {
    width: 100%;
    margin:10px;
    padding: 20px;
    background-color : #800080; 
    border-radius: 15px;
  }


  .RoleView__Columns__right {
    display: flex;
    margin-top: 80px;
    padding:50px;
    width: 50%;
    min-width: 500px;
    max-width: 700px;
    background: #36C6F4;
    flex-direction: column;
    justify-content: flex-start;
  }

  .RoleView__Columns__right_block {
    display: flex;
    font-family: 'ComicNeueAngular';
    color: #f1f1f1;
    font-size: 22px;
    font-weight: bold;
    width: 50%;
    padding: 50px;
    background: #36C6F4;
    flex-direction: column;
    justify-content: space-between;
  }
  .vert__spacer {
    height: 20px;
  }
  
  /* The header */
  .RoleView__header {
    font-family: 'ComicNeueAngular';
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: #f1f1f1;
    background: #36C6F4;
    padding: 40px;
  }

  .RoleView_Title{
    font-family: 'ComicNeueAngular';
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    color: #f1f1f1;
    background: #36C6F4;
    padding: 20px;
  }

  .InputRow {
    font-family: 'ComicNeueAngular';
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100px;
    width: 100%;

  }
  
  .ButtonRow {
    font-family: 'ComicNeueAngular';
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .RoleView__btn {
    font-family: 'ComicNeueAngular';
    bottom: 10px;
    width: 100px;
    height: 60px;
    margin-left: 10px;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 38px;
    font-weight: bold;
    color: #f1f1f1;
    background: #E22380;
    cursor: pointer;
    border-radius: 20px;
  }

  .RoleView__undo__btn {
    font-family: 'ComicNeueAngular';
    margin-top: 10px; 
    bottom: 10px;
    width: 80px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #f1f1f1;
    background: #E22380;
    cursor: pointer;
    
  }
  .RoleView__undo__btn:hover {
    background: #A42864;
  }

  .RoleView__save__btn {
    font-family: 'ComicNeueAngular';
    margin-left: 10px;
    margin-top: 10px;
    bottom: 10px;
    width: 180px;
    height: 50px;
    border-radius: 20px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    color: #f1f1f1;
    background: #E22380;
    cursor: pointer;
  }
  
  .RoleView__btn:hover {
    background: #A42864;
  }
  .RoleView__save__btn:hover {
    background: #A42864;
  }
  .RollView_Code_badge{
    height:100,

  }
  /* Textinput */
  .RoleView__textInput {
    font-family: 'ComicNeueAngular';
    bottom: 0;
    padding: 10px;
    width: 100%;
    height: 60px;
    line-height: 80px;  
    background: #fff;
    font-size: 14px;
    color: #084D95;
    border-radius: 15px;
  }

  .RoleView__bottom {
    font-family: 'ComicNeueAngular';
    padding-top: 15px;
    height: 100px;
    font-size: 28px;
    font-weight: bold;
    color: #084D95;
    flex-direction: 'flex-end';
   
  }

  .RollView_left_123block {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 80px;
  }

  .RollView_right_123block {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    padding:20px;
    border-radius: 25px;
    background-color:#f1f1f141 ;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .RollView_right_123block-code {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:#084D95;

    padding:40px;
    border-radius: 25px;
    background-color:#f1f1f141 ;
    margin-top: 10px;
    margin-bottom: 40px;
  }


  .RollView_right_123block_number {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #f1f1f1;
  }
  .top-spacer{
    margin-top: 40px;
    margin-left: 40px;
  }

  .RolesView_right_close{
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-right: -25px;
    margin-top: -25px;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #E22380;
  }

  .RoleView__feedback_rollenover_item {
    font-family: 'ComicNeueAngular';
    font-size: 14px;
    color:#084D95;
    background-color:#f1f1f141 ;
    padding: 5px;
    margin: 1px;
  }

  .RoleView__badge {
    font-family: 'ComicNeueAngular';
    font-size: 18px;
    font-style: italic;
    color: #E22380;
    text-align: center;
    background-color:#f1f1f141 ;
    padding: 20px;
    margin: 5px;
  }



.Black__Number {
  color:#084D95;
  font-size: 28px;
}


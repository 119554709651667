

*
  /* Our App Container */
  .LoginView__container {
    justify-content: center;
    align-items: center;
  }

  .LoginView__container__container  {
   
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;


  }


  .LoginView__loginCard2{
    font-family: 'ComicNeueAngular';
    font-size: 18px;
    color: #084D95;
    display: flex;
    margin-top: 190px;
    padding:20px;
    max-width: 500px;
    background: #efefee;
    justify-content: center;
    flex-direction: column;
    border-radius: 30px;
    align-items: center;
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
  }

  .LoginView__btn {
    font-family: 'ComicNeueAngular';
    z-index: 1;
    height: 50px;
    border-radius: 20px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    color: #f1f1f1;
    background: #E22380;
    cursor: pointer;
  }

  .MessageView__Columns__right {
    display: flex;
    width: 50%;
    min-width: 500px;
    max-width: 700px;
    padding:50px;
    background: #36C6F4;
    flex-direction: column;
    justify-content: flex-start;
  }

  .LoginView__btn__Loading {
    font-family: 'ComicNeueAngular';
    z-index: 1;
    height: 50px;
    border-radius: 20px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    color: #f1f1f1;
    background: #E22380;
    cursor: pointer;
  }

  .LoginView__btn:hover {
    background: #A42864;
  }

  .Feedback{
    font-family: 'ComicNeueAngular';
    font-size: 16px;
    font-weight: bold;
    color: #E22380;
    padding: 5px;
  }


  .LoginView__textCloud {
    resize: none;
    font-family: 'ComicNeueAngular';
    bottom: 0;
    height: 60px;
    margin: 10px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    border-radius: 10px; 
    border-width: 0px;
    outline: none;
    background: #fff;
    font-size: 16px;
    color: #084D95;

  }

  .funnyImage {
    position: fixed;
    right: 20px;
    bottom: 20px;

  }






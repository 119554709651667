@font-face {
  font-family: 'ComicNeueAngular';
  src: local('ComicNeueAngular-Regular'), url(./fonts/ComicNeueAngular-Regular.ttf) format('truetype');
  src: local('ComicNeueAngular-Bold'), url(./fonts/ComicNeueAngular-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'ComicNeueAngular';
  font-weight: light;
  color:#f1f1f1;
  font-size: 14pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: 22pt;
  font-weight: light;
  padding-top: 10px;
  padding-left: 20px;
}
h2 {
  font-size: 18pt;
  font-weight: light;
  padding-top: 10px;
  padding-left: 20px;
}
h3 {
  font-size: 14pt;
  font-weight: light;
  padding-top: 10px;
  padding-left: 20px;
}
p {
  padding: 20px;
  font-weight: light;
}

a {
  margin: 0;
  font-family: 'ComicNeueAngular';
  font-weight: light;
  color:#f1f1f1;
  font-size: 12pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a:hover {
  color: #A42864;
  font-weight: light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Friebeltje{
  margin-top: -10px;
  margin-left: 40px;
}



* {
  margin: 0px;
  box-sizing: border-box;
}
html, body {
  width: 100%;
  height: 100%;
}
body {
  background: #36C6F4;
}
#root {
  width: 100%;
  height: 100%;
}
/* Our App Container */
.container {
  width: 100%;
  height: 100%;
}


.whiteText {
  color: #ffffff;
}
/* 
.rowLeftAlign {
  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;



  justify-content: flex-start;
  align-items: center;
} */

.link_left {

  justify-content: flex-end;
  padding: 5px;
  align-items: center;
  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;

}

.menuBar  {
  position: fixed;
  z-index: 1;
  top:60px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #E22380;
  background: rgb(101, 213, 250);
  padding: 10px;

  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;

  webkit-box-flex: 1 1 ;
  moz-box-flex: 1 1 ;
  ms-flex: 1 1 ;
  webkit-flex: 1 1 ;
  flex: 1 1 ;

  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
}


.selectBar{
  position: fixed;
  z-index: 2;
  top: 0px;
  height: 60px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #E22380;
  background: #fff;
  padding: 10px;

  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;

  webkit-box-flex: 1 1 60px;
  moz-box-flex: 1 1 60px;
  ms-flex: 1 1 60px;
  webkit-flex: 1 1 60px;
  flex: 1 1 60px;

  justify-content: left;
  align-items: center;

  box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
}

.logOut {
  font-size: 14px;
  cursor: pointer;
  color: #E22380;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
  margin-left: auto;
}

.menuItem {
  font-size: 18px;
  min-width: 120px;
  justify-content: center;
  align-self: left;
  cursor: pointer;
  color: #E22380;
  background-color:#f1f1f1be ;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
  margin-left: 20px;

}

/* The header */
.header {
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
  color: #f1f1f1;
  background: #299FC5;
  padding: 1px;
}

.img-fluid {
  max-height: 40px;
  width: auto;
  cursor: pointer;
}


.btn {
  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: 'ComicNeueAngular';
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  bottom: 10px;
  width: 180px;
  height: 50px;
  border-radius: 20px;
  font-size: 20px;
  color: #f1f1f1;
  background: #E22380;
  cursor: pointer;
}

.btn_loading {
  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: 'ComicNeueAngular';
  font-size: 20px;
  margin-left: 20px;
  margin-top: 10px;
  bottom: 10px;
  width: 180px;
  height: 50px;
  border-radius: 20px;
  color: #f1f1f1;
  background: #E22380;
}

.MessageView__btn:hover {
  background: #A42864;
}


.RoleView__feedback {
  font-family: 'ComicNeueAngular';
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: #f1f1f1;
  background: #36C6F4;
  padding: 5px;
}

.RoleView__rollenover {
  webkit-box-flex-direction: row;
  moz-box-flex-direction: row;
  ms-flex-direction: row;
  webkit-flex-direction: row;
  flex-direction: row;
  flex: 1;
  font-family: 'ComicNeueAngular';
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: #f1f1f1;
  padding: 15px;
  margin-top: 25px;
}



.loader {
  border: 4px solid rgb(233, 73, 150); /* Light grey */
  border-top: 4px solid #FFFFFF; /* Blue */
  border-radius: 11px;
  width: 22px;
  height: 22px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

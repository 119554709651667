/* The user */
.RolesUser__rollenUser {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    webkit-box-flex-direction: column;
    moz-box-flex-direction: column;
    ms-flex-direction: column;
    webkit-flex-direction: column;
    flex-direction: column;
  
    webkit-box-flex: 1 100%;
    moz-box-flex: 1 100%;
    ms-flex: 1 100%;
    webkit-flex: 1 100%;
    flex: 1 100%;
  
    justify-content: space-around;
    padding:10px;
    color: #084D95;
    margin: 5px;
    min-height: 100px;
    max-height: 180px;
    background: #fff;
    border: solid 1px #e9e9e9;
    cursor: pointer;
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
    transition: transform .2s ease;
    border-radius: 10px;
  }
  
  .RolesUser__headerRollen{
  
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    webkit-box-flex-direction: row;
    moz-box-flex-direction: row;
    ms-flex-direction: row;
    webkit-flex-direction: row;
    flex-direction: row;
  
    webkit-box-flex: 1 1 30px;
    moz-box-flex: 1 1 30px;
    ms-flex: 1 1 30px;
    webkit-flex: 1 1 30px;
    flex: 1 1 30px;
  
    justify-content: space-between;
    background: #fff;
    color: #084D95;
    height: 20px; 
    margin-bottom: 5px;
   
  }

  .RolesUser__Name{
    font-family: 'ComicNeueAngular';
     font-size: 18px;
     font-weight: bold;
     color: #E22380;
     cursor: pointer;
  }

  .letoptext{
    font-family: 'ComicNeueAngular';
    font-size: 10px;
    color: #9b9b9b;
    padding: 2px;
  }

  .RolesUser__delBtn{
    font-family: 'ComicNeueAngular';
     font-size: 10px;
     font-weight: bold;
     color: #E22380;
     cursor: pointer;
  }

  
.message {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    webkit-box-flex-direction: row;
    moz-box-flex-direction: row;
    ms-flex-direction: row;
    webkit-flex-direction: row;
    flex-direction: row;
  
    webkit-box-flex: 1 1 200px;
    moz-box-flex: 1 1 200px;
    ms-flex: 1 1 200px;
    webkit-flex: 1 1 200px;
    flex: 1 1 200px;
    margin-top:40px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 150px;
    
    color: #084D95;
    background-color:#ffffff;
   
    border-radius: 20px;
    cursor: pointer;
   

  }
  .messageBody {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    webkit-box-flex-direction: row;
    moz-box-flex-direction: row;
    ms-flex-direction: row;
    webkit-flex-direction: row;
    flex-direction: row;
    padding-left:45px;
    padding-right:25px;
    padding-bottom:30px;
    justify-content: space-between;
    align-items: center;
    color: #084D95;
    cursor: pointer;
  }

  .modalBody{
    margin:30px;
    align-items: center;
    color: #888;
  }
  .messageHeader {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    webkit-box-flex-direction: row;
    moz-box-flex-direction: row;
    ms-flex-direction: row;
    webkit-flex-direction: row;
    flex-direction: row;
    padding:15px;
 
    justify-content: space-between;
    align-items: center;
    color: #084D95;
    font-size: 10px;
    cursor: pointer;
  }

  .messageFooter {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    webkit-box-flex-direction: row;
    moz-box-flex-direction: row;
    ms-flex-direction: row;
    webkit-flex-direction: row;
    flex-direction: row;
    margin-right:10px;
 
    justify-content: flex-end;
    align-items: center;
    color: #333333;
    font-size: 10px;
    cursor: pointer;
  }
  .messageHeaderLeft {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    webkit-box-flex-direction: row;
    moz-box-flex-direction: row;
    ms-flex-direction: row;
    webkit-flex-direction: row;
    flex-direction: row;
    margin-left: 15px;
 
    justify-content: flex-start;
    align-items: center;
    color: #084D95;
    font-size: 14px;
    cursor: pointer;
  }

  
  .MessageView_Message__delMsgBtn {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: transform .06s ease;
    align-self: center;
  }

  .MessageView_Message__delMsgBtn:hover {
   
  }


  .MessageView_Message__modalDelMsgBtn{
    color: #E22380;
    font-size: 20px;
    cursor: pointer;
  }

  .MessageView_Message__modalDelMsgBtn:hover {
    background: #A42864;
  }


  .profilPicSmall {
    max-width: 30px;
    height: auto;
    margin-right: 10px;
    border-radius: 15px;
  }

  .MessageView_right_close{
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #E22380;
  }

  .MessageView_right_close:hover {
    background: #A42864;
  }





*
  /* Our App Container */
  .MessageView__container {
    padding-bottom: 80px;
  }
  
  /* The header */
  .MessageView__header {
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: #f1f1f1;
    background: #3367d6;
    padding: 40px;
  }
  
  /* The add note button */
  .MessageView__Columns__left {
    
    display: flex;
    padding:50px;
    width: 50%;
    min-width: 500px;
    max-width: 700px;
    background: #36C6F4;
    flex-direction: column;
  }

  .MessageView__textCloud {
    bottom: 0;
    margin: 20 20 20 0;
    min-height: 120px;
    width: 100%;
    padding: 20px 15px 10px 20px;
    border-radius: 20px; 
    border-width: 0px;
    outline: none;
    background: #fff;
    font-size: 16px;
    color: #084D95;

  }

  .RollView_left_sendmessage {
    display: flexbox;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding:20px;
    border-radius: 25px;
    background-color:#f1f1f141 ;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .MessageView__user {
    resize: none;
    bottom: 0;
    margin-left: 20px;
    height: 60px;
    width: 60%;
    padding: 20px 20px 20px 20px;
    border-radius: 10px; 
    border-width: 0px;
    outline: none;
    background: #fff;
    font-size: 16px;
    color: #084D95;
  }

  



